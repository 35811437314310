<template>
    <Loading :isLoading="isLoading" />
    <div class="flex ml-5 mt-5 cursor-pointer" @click="redirect_history()" >
        <img src="@/assets/back-arrow.svg">Atrás
    </div>
    <div class="bg-white m-0 px-5 md:px-3 md:m-5 ">
        <div class="grid grid-cols-1 md:grid-cols-2 px-0 md:px-0 lg:px-20 mt-10">

            <div class="w-full md:w-4/5 md:mx-auto mt-5">
                <p class="text-3xl font-bold roboto-regular mb-4">{{ questionTitle }}</p>
                <QuotationAlcoholConsumptionComponent
                    ref="ComponentsAlcoholRef"
                    :view_class_view="view_class_view" 
                    :validations="validations" 
                    :buttons_styles="buttons_styles"
                    :buttonsQuestionAlcohol="buttonsQuestionAlcohol"
                    :alcoholTypeList="alcoholTypeList"
                    :quantityAlcoholList="quantityAlcoholList"
                    :frecuencyAlcoholList="frecuencyAlcoholList" />
                
                <div class="w-full flex justify-center">
                   <input type="button" id="button_next" :disabled="!isActive" :class="{'button-disabled cursor-default': !isActive, 'bg-mustardDark cursor-pointer': isActive}" class="w-full text-white px-5 py-2 mx-auto my-4 h-14 md:h-14  rounded text-center font-medium text-xl" @click="next()" value="Continuar">        
                </div>
            </div>
            <div class="w-full bg-white hidden md:block md:py-14">
                <img src="@/assets/Cuenta/vidaSimple/alcohol.svg" :alt="questionTitle" class="mx-auto" >
            </div>
        </div> 
    </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { helpers, requiredIf } from '@vuelidate/validators';

import Loading from '@/components/Loading/VueLoading.vue';
import { useRouter, useRoute } from 'vue-router';
import Quote from '@/classes/Quotation.js';
import { availableFormStorage } from '@/helpers/storage.js';
import moment from 'moment';

export default {
    name: "Alcohol",
    setup(){
        const router = useRouter();
        const route = useRoute();
        const isLoading = ref(true);
        const questionTitle = ref("Alcohol");
        const ComponentsAlcoholRef = ref(null);
        const category = ref("");
        const quoteClass = ref(null);

        const buttonsQuestionAlcohol = [
            { id: 'button_alcohol_consumes_yes', value: 'Sí', text: 'Sí' },
            { id: 'button_alcohol_consumes_no', value: 'No', text: 'No' }
        ];
        const buttonsQuestionAlcoholNarcoticsClass = [
            { id: 'button_alcohol_consumes_yes', value: 'Sí', text: 'Sí' },
            { id: 'button_alcohol_consumes_no', value: 'No', text: 'No' }
        ];
        const alcoholTypeList = [
            { id: 'option_cerveza', value: 'Cerveza', text: 'Cerveza'},
            { id: 'option_cino', value: 'Vino', text: 'Vino'},
            { id: 'option_tequila', value: 'Tequila', text: 'Tequila'}
        ];
        const quantityAlcoholList = [
            { id: 'option_1', value: '1', text: '1'},
            { id: 'option_2', value: '2', text: '2'},
            { id: 'option_3', value: '3', text: '3'},
            { id: 'option_4', value: '4', text: '4'},
            { id: 'option_4', value: '5', text: '5'},
            { id: 'option_4', value: '6', text: '6'},
            { id: 'option_4', value: '7', text: '7'},
            { id: 'option_4', value: '8', text: '8'},
            { id: 'option_4', value: '9', text: '9'},
            { id: 'option_4', value: '10', text: '10'}
        ];
        const frecuencyAlcoholList = [
            { id: 'option_1', value: 'diario', text: 'Diario'},
            { id: 'option_2', value: 'semanal', text: 'Semanal'},
            { id: 'option_3', value: 'quincenal', text: 'Quincenal'},
            { id: 'option_4', value: 'mensual', text: 'Mensual'}
        ];
        let validations = {
            consumes: {
                required: helpers.withMessage('El campo es requerido', requiredIf(function(){
                    return true;
                }))
            },
            you_consumes: {
                required: helpers.withMessage('El campo es requerido', requiredIf(function(value, e){
                    return e.consumes == 'Sí';
                }))
            },
            quantity: {
                required: helpers.withMessage('El campo es requerido', requiredIf(function(value, e){
                    return e.consumes == 'Sí';
                }))
            },
            frecuency: {
                required: helpers.withMessage('El campo es requerido', requiredIf(function(value, e){
                    return e.consumes == 'Sí';
                }))
            },
            completion_date: {
                // required: helpers.withMessage('El campo es requerido', requiredIf(function(value, e){
                //     return false;
                // })),
                isValid: helpers.withMessage('La fecha selecionada no es permitida.', function(value, e){
                    if(e.consumes == 'No') { return true };
                    if(e.completion_date == "") { return true };
                    var currentDate = moment().startOf('day');
                    var birthday = moment(value.replaceAll('/','-'), 'YYYY-MM-DD').startOf('day');
                    return currentDate.isAfter(birthday);
                })
            },
            
        };
        const view_class_view = {
            classInputs:{
                valid:"border-blue-600",
                no_valid:"border-red-600",
                default:"w-full py-2 px-2 rounded-md border-2 border-sm focus:border-blue-600 mb-2 mt-2 shadow-md focus:outline-none "
            },
            div_container_error:{
                container:"my-2",
                text_error:"text-red-600",
            },
            div_container:"grid grid-cols-1 sm:grid-cols-3 sm:gap-10",
        };
        const buttons_styles = {
            buttonSelected:"",
            activeColorBackground: '#feb72b !important',
            inactiveColorBackground: '#FFFFFF !important',
            activeColorText: '#FFFFFF !important',
            inactiveColorText: '#feb72b !important',
            activeBorderColor: '#feb72b',
            inactiveBorderColor: '#feb72b'
        };

        let isActive = ref(true);

        onMounted(async () => {
            quoteClass.value = new Quote();
            category.value = getCategory();

            isLoading.value = false;
            isActive.value = true;
        
            const quote = quoteClass.value.get(category.value);
            if(quote.is_additional_insured == "Para Adicional")
            {
                configuration_for_aditional();
            }
            
            loadFormStorage();
        });

        function configuration_for_aditional()
        {
            set_title();
        }

        const loadFormStorage = () => {
            try{
                if(!availableFormStorage('quote-alcohol')) return;
                
                const data = getStorage('quote-alcohol');
                const chooseButtonSelected = data.consumes == 'Sí'? 'yes':'no';
                const input = document.getElementById(`button_alcohol_consumes_${chooseButtonSelected}`);
                input.click();

                ComponentsAlcoholRef.value.alcoholConsumptionModel.you_consumes = data.you_consumes;
                ComponentsAlcoholRef.value.alcoholConsumptionModel.quantity = data.quantity;
                ComponentsAlcoholRef.value.alcoholConsumptionModel.frecuency = data.frecuency;
                ComponentsAlcoholRef.value.alcoholConsumptionModel.completion_date = data.completion_date;
            }catch(error){
                console.log("error:", error)
            }
        }

        function set_title()
        {
            questionTitle.value += " para adicional";
        }
        
        const getCategory = () => {
            return route.params.product;
        }

        const saveData = (values, category) => {
            const nameStorage = `quote-${category}`;
            
            let quote = getStorage(nameStorage);
            quote.alcohol = values;
            createStorage(nameStorage, quote)
        }

        const createStorage = (name, value) => {
            localStorage.setItem(name, JSON.stringify(value));
        }
        
        const getStorage = (name) => {
            return JSON.parse(localStorage.getItem(name));
        }
        
        const next = async ()   => {
            const ComponentsAlcoholRefs = ComponentsAlcoholRef.value.execute_validation();
            if(!ComponentsAlcoholRefs) return false ;
            createStorage('quote-alcohol', ComponentsAlcoholRefs);
            saveData(ComponentsAlcoholRefs, category.value);
            router.push('cobertura')
        }

        const redirect_history = () => router.go(-1);

        return { 
            buttonsQuestionAlcohol,
            buttonsQuestionAlcoholNarcoticsClass,
            alcoholTypeList,
            quantityAlcoholList,
            frecuencyAlcoholList,
            validations,
            view_class_view,
            buttons_styles,
            isActive,
            next,
            questionTitle,
            ComponentsAlcoholRef,
            redirect_history
        }
    },
    components:{
        Loading
    }

}
</script>
<style>
.question-container > div > div {
    justify-content: space-between;
    width: 100%;
    margin-top: 5px;
    margin-bottom: 15px;
}

.question-container > div > div > button{
    border-radius:5px;
    width:49%;
}

.question-container>div>label, .question-container>div>input, .question-container>div>button {
     font-family: "roboto-regular";
}

.question-container > div > div > button.active {
    background-color: #810042;
    color: white;
    border-color: #810042;
}

.question-container > div > div > button {
    box-sizing: border-box;
    background: #D9D9D9;
    color: white;
    border-radius: 5px;
    font-size: 18px;
    margin-inline: 1px;
}

.question-container>div>label {
    font-size: 18px;
    line-height: 30px;
    color: #485462;
}

.card-shadow {
  box-shadow: 0px 4px 6px -3px rgb(178 176 176);
  -webkit-box-shadow: 0px 4px 6px -3px rgb(178 176 176);
  -moz-box-shadow: 0px 4px 6px -3px rgb(178 176 176);
}

.content_textarea_0 {
    margin-top: 20px;
}

.content_date_3, .content_date_4 {
    margin-top: 9px;
}

.vpd-input-group input {
    flex-grow: 1;
    border: 2px solid #e5e7eb;
    border-right: 2px solid #e5e7eb;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    line-height: 30px;
    padding: 0 10px;
    height: 43px;
}

.content_textarea_6 {
    display:flex;
}

.content_checkbox_1 > div {
    width:100%!important;
}


@media only screen and (min-width: 600px) {
    .content_date_3, .content_date_4 {
        display: block;
    }
}

@media only screen and (max-width: 600px) {
    .content_date_3, .content_date_4 {
        display: flex;
    }
}

#input_rfc_contracting_data_1,
#input_curp_contracting_data_2{
    text-transform: uppercase;
}
</style>
<style scoped>
.button-disabled {
    background-color: #EDD191;
}



@media only screen and (min-width: 600px) {
    .detail-container {
        background-color: #FAFAFA;
    }
    .content_date_3, .content_date_4 {
        display: block;
    }
}
@media only screen and (max-width: 600px) {
    .bg-container {
        background-color: #FCFCFC;
    } 
    .content_date_3, .content_date_4 {
        display: flex;
    }
}
button {
    font-size: 19px!important;
}

</style>