<template>
    <html>
      <body class="" style="background: #FFF">
        <Header />
        <MenuLateralMobile/>
        <div class="h-screen grid grid-cols-6 grid-rows-12 grid-flow-col">
           <MenuLateral/>
            <div class="bg-container col-span-6">
                <AlcoholComponents />
            </div>
        </div>
      </body>
    </html>
</template>

<script>
import AlcoholComponents from "@/components/Shared/Products/Quote/Alcohol.vue";
import Header from "@/components/Administrative/Header/Header.vue";
import MenuLateralMobile from '@/components/MenuLateralMobile.vue';
import MenuLateral from '@/components/LateralMenu.vue';

export default {
    setup(){
        return {}
    },
    components:{
        Header,
        MenuLateralMobile,
        MenuLateral,
        AlcoholComponents
    }
}
</script>

<style scoped>
.description {
    font-family: 'roboto-regular';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 33px;
    color: #485462;
}
</style>